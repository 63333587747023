<template>
  <b-btn
    class="ml-auto"
    size="sm"
    variant="primary"
    v-b-tooltip.hover
    :title="$t('general.add')"
    v-b-modal.modal-add-master-case
  >
    <b-icon icon="plus" />
    <b-modal
      id="modal-add-master-case"
      ref="modal"
      :title="$t('views.master-form.title')"
      @show="onShowModal"
      @hidden="resetModal"
      :busy="busyPost || busyGet"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      small
    >
      <b-overlay :show="busyPost || busyGet">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :label="$t('views.master-form.name')"
            label-for="name-input"
            :invalid-feedback="$t('views.master-form.nameRequired')"
            label-cols="4"
            :state="state.name"
            :disabled="busyPost || busyGet"
          >
            <b-form-input
              id="name-input"
              autofocus
              autocomplete="off"
              :placeholder="$t('views.master-form.nameInstructions')"
              v-model="form.name"
              :state="state.name"
              :disabled="busyPost || busyGet"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('views.master-form.organizational-unit')"
            label-for="organizational-unit-select"
            :invalid-feedback="$t('views.master-form.organizationUnitRequired')"
            label-cols="4"
            :state="state.idOrganizationalUnit"
            :disabled="busyPost || busyGet"
            ><b-form-select
              v-model="form.idOrganizationalUnit"
              :options="options"
              :state="state.idOrganizationalUnit"
              :disabled="busyPost || busyGet"
              text-field="nameOrganizationalUnit"
              value-field="idOU"
              required
            >
              <template #first>
                <b-form-select-option :value="null" disabled>{{
                  $t("views.master-form.OUInstructions")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label="$t('views.master-form.parent')"
            label-for="parent-select"
            label-cols="4"
            :disabled="busyPost || busyGet"
            :state="state.idParent"
            :invalid-feedback="$t('views.master-form.parentRequired')"
            ><b-form-select
              v-model="form.idParent"
              :options="generalMasterFormOptions"
              :disabled="busyPost || busyGet"
              text-field="name"
              value-field="idMasterForm"
              :state="state.idParent"
            >
              <template #first>
                <b-form-select-option :value="null" v-if="checkParentOU">{{
                  $t("views.master-form.none")
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </form>
      </b-overlay>
      <template v-slot:modal-footer>
        <slot name="footer">
          <b-button @click="$bvModal.hide('modal-add-master-case')">{{
            $t("general.cancel")
          }}</b-button
          ><b-button variant="primary" @click="handleOk">{{
            $t("general.ok")
          }}</b-button></slot
        >
      </template>
    </b-modal>
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const initData = {
  form: {
    idOrganizationalUnit: null,
    name: null,
    idParent: null,
  },
  state: {
    idOrganizationalUnit: null,
    name: null,
    idParent: null,
  },
};
export default {
  data() {
    return {
      form: { ...initData.form },
      state: { ...initData.state },
    };
  },
  computed: {
    ...mapGetters("Cases", ["organizationalUnits"]),
    ...mapGetters("MasterForm", ["busyPost", "busyGet", "generalMasterForms"]),
    options() {
      return this.organizationalUnits.organizationalUnits;
    },
    generalMasterFormOptions() {
      return this.generalMasterForms;
    },
    checkParentOU() {
      if (this.form.idOrganizationalUnit != null) {
        const selectedOU = this.options.find(
          (x) => x.idOU == this.form.idOrganizationalUnit
        );
        if (selectedOU.isGeneral) return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("Cases", ["fetchOUs"]),
    ...mapActions("MasterForm", ["postMasterForms", "getGeneralMasterForms"]),
    onShowModal() {
      this.fetchOUs({
        session: this.$session,
        api: this.$api,
      });
      this.getGeneralMasterForms({
        session: this.$session,
        api: this.$api,
      });
    },
    checkFormValidity() {
      let valid =
        (this.state.idOrganizationalUnit =
        this.state.name =
        this.state.idParent =
          true);
      if (!this.form.idOrganizationalUnit)
        this.state.idOrganizationalUnit = valid = false;
      else {
        const selectedOU = this.options.find(
          (x) => x.idOU == this.form.idOrganizationalUnit
        );
        if (!this.form.idParent && !selectedOU.isGeneral)
          this.state.idParent = valid = false;
      }
      if (!this.form.name) this.state.name = valid = false;
      return valid;
    },
    resetModal() {
      this.form = { ...initData.form };
      this.state = { ...initData.state };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.postMasterForms({
        session: this.$session,
        api: this.$api,
        idOrganizationalUnit: this.form.idOrganizationalUnit,
        name: this.form.name,
        idParent: this.form.idParent,
      })
        .then(() => {})
        .finally(() => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-add-master-case");
          });
        });
    },
  },
};
</script>

<style></style>
