<template>
  <span>
    <Alert />
    <b-card class="h-100 shadow-sm" no-body>
      <b-card-header>
        <b-row class="mt-1">
          <b-col>
            <h4>
              {{
                $t("views.master-form.title-list", [SearchMasterForms.length])
              }}
            </h4>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
                <b-checkbox
                  v-model="filtersShown"
                  name="filtersShown-button"
                  button
                  button-variant="primary"
                  class="ml-1"
                  size="sm"
                  v-b-popover.hover.top="{
                    customClass: 'blackPopup',
                    content: $t('general.filter'),
                  }"
                >
                  <b-icon icon="funnel" />
                </b-checkbox>
              </b-button-group>
              <b-button-group class="mx-2">
                <add-master-form />
                <b-btn
                  variant="primary"
                  v-b-tooltip.hover
                  :title="$t('general.view')"
                  size="sm"
                  :disabled="!selectedMasterForm.idMasterForm"
                  :to="{
                    name: 'MasterForm',
                    params: { idMasterForm: selectedMasterForm.idMasterForm },
                  }"
                >
                  <view-icon />
                </b-btn>
                <remove-master-form />
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
        <div v-if="filtersShown">
          <b-row class="mt-2">
            <b-col>
              <b-form-group
                :label="$t('views.master-form.organizational-unit')"
                label-for="organizational-unit-filter"
                label-cols="4"
                content-cols="8"
              >
                <b-form-select
                  v-model="OUSelectedFilter"
                  :options="OUFilter"
                  class="mr-2 ml-2"
                  id="organizational-unit-filter"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('views.master-form.status')"
                label-for="status-filter"
                label-cols="4"
                content-cols="8"
              >
                <b-form-select
                  id="status-filter"
                  v-model="statusSelectedFilter"
                  :options="StatusCases"
                  class="mr-2 ml-2"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card-header>
      <b-card-body class="p-0 overflow-auto">
        <b-list-group>
          <b-list-group-item
            v-if="busy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <simple-loader />
          </b-list-group-item>
          <b-list-group-item
            v-else
            v-for="item in SearchMasterForms"
            :key="item.idMasterForm"
            active-class="primary"
            :active="selectedMasterForm.idMasterForm == item.idMasterForm"
            button
            @click="onMasterCaseClick(item)"
          >
            <div>
              <b-row>
                <b-col cols="12" sm="6">
                  <strong>{{ $t("views.master-form.name") }}</strong
                  >{{ item.name }}

                  <font-awesome-icon
                    id="disabled-wrapper"
                    icon="crown"
                    class="text-warning"
                    v-if="item.default"
                  />
                  <b-tooltip target="disabled-wrapper">{{
                    $t("views.master-form.deafult")
                  }}</b-tooltip>
                </b-col>
                <b-col cols="12" sm="6">
                  <strong>{{ $t("views.master-form.status") }}</strong
                  >{{ $t("status." + item.idStatus) }}
                </b-col>
                <b-col cols="12" sm="6">
                  <strong>{{
                    $t("views.master-form.organizational-unit")
                  }}</strong>
                  {{ item.organizationalUnit }}
                </b-col>
                <b-col cols="12" sm="6">
                  <strong>{{ $t("views.master-form.last-modified") }}</strong>
                  {{ FormatDate(item.lastModified) }}
                </b-col>
                <b-col cols="12" sm="6">
                  <strong> {{ $t("views.master-form.parent") + ":" }}</strong>
                  {{
                    item.idParent ? item.parent : $t("views.master-form.none")
                  }}
                </b-col>
              </b-row>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card-body>
    </b-card>
  </span>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddMasterForm from "@/components/MasterCase/AddMasterForm.vue";
import RemoveMasterForm from "@/components/MasterCase/RemoveMasterForm.vue";
import Alert from "@/components/general/alert";
import moment from "moment";
import ViewIcon from "@/components/general/icons/View.vue";

export default {
  components: {
    "add-master-form": AddMasterForm,
    "remove-master-form": RemoveMasterForm,
    Alert,
    "view-icon": ViewIcon,
  },
  created() {
    this.getMasterForms({
      session: this.$session,
      api: this.$api,
    });

    this.fetchOUs({
      session: this.$session,
      api: this.$api,
    });
  },
  data() {
    return {
      masterForm: null,
      filtersShown: false,
      statusSelectedFilter: null,
      OUSelectedFilter: null,
      keyword: "",
    };
  },
  computed: {
    ...mapGetters({
      caseStatus: "setCaseStatus",
      subcaseStatus: "setSubcaseStatus",
      masterFormStatus: "getMasterFormStatus",
    }),
    ...mapGetters("MasterForm", ["masterForms", "busy", "selectedMasterForm"]),
    ...mapGetters("Cases", ["organizationalUnits"]),
    items() {
      return this.masterForms;
    },
    OUFilter() {
      let OU = [{ value: null, text: this.$t("HeaderCase.None") }];
      if (this.organizationalUnits.organizationalUnits) {
        this.organizationalUnits.organizationalUnits.forEach((element) => {
          OU.push({
            value: element.idOU,
            text: element.nameOrganizationalUnit,
          });
        });
      }
      return OU;
    },
    StatusCases() {
      const that = this;
      return this.masterFormStatus
        ? this.masterFormStatus.map(function (old) {
            return {
              value: old.value,
              text: that.$t(old.text),
            };
          })
        : this.masterFormStatus;
    },
    SearchMasterForms() {
      let byStatusCases = this.statusSelectedFilter
        ? this.items.filter((x) => x.status === this.statusSelectedFilter)
        : this.items;

      let byOUCases = this.OUSelectedFilter
        ? byStatusCases.filter(
            (x) => x.idOrganizationalUnit === this.OUSelectedFilter
          )
        : byStatusCases;

      let search = byOUCases.filter((x) =>
        x.name.toLocaleLowerCase().includes(this.keyword.toLocaleLowerCase())
      );

      return search;
    },
  },
  methods: {
    ...mapActions("MasterForm", ["getMasterForms", "setSelectedMasterForm"]),
    ...mapActions("Cases", ["fetchOUs"]),
    onMasterCaseClick(item) {
      this.setSelectedMasterForm(item);
    },
    FormatDate(date) {
      if (date) {
        return moment
          .utc(String(date))
          .local()
          .format(this.$t("caselist.dateformat"));
      }
      return "";
    },
  },
};
</script>

<style>
.blackPopup {
  color: #fff;
  background-color: #000;
}

.popover-body {
  color: #fff;
}

.arrow:after {
  border-top-color: #000;
}
</style>
