<template>
  <b-btn
    variant="primary"
    size="sm"
    :disabled="!selectedMasterForm.idMasterForm"
    @click="onClick"
    v-b-tooltip.hover
    :title="$t('general.delete')"
  >
    <b-icon icon="trash" />
  </b-btn>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("MasterForm", ["selectedMasterForm"]),
  },
  methods: {
    ...mapActions("MasterForm", ["removeMasterForms"]),
    onClick() {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete selected case?",
          {
            title: "Please Confirm",
            okVariant: "danger",
            centered: true,
            headerClass: "bg-danger text-light",
          }
        )
        .then((value) => {
          if (!value) return;
          this.removeMasterForms({
            session: this.$session,
            api: this.$api,
            idMasterForm: this.selectedMasterForm.idMasterForm,
          });
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
